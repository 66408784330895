<style src="./index.less" lang="less" scoped></style>
<template src="./index.html"></template>
<script>
import moment from 'moment';
import { returnedDetail } from '@/api/returnOrder'

export default {
  name: "gather",
  data() {
    let year = moment().year();
    let month = moment().month() + 1;
    let year_options = [];
    let year_before  = year - 10; // 向前查询10年
    for(let val = year_before; val <= year; val++ ){
      year_options.push({
        value: val,
        label: val + '年'
      })
    }
    let month_options = new Array(month).fill({ value: '', label: '' }).map((val, index) => {
      index += 1;
      return{
        value: index,
        label: index + '月'
      }
    })
    return {
      year,
      year_options,
      month,
      month_options,
      show_table : false,
      allCny     : '',
      yt         : '',
      total      : 0,
      lists      : [],
      pages      : {
        current: 1
      }
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getRange() {
      let momentValue = this.year +  '/' +  this.month
      let atDay       = moment().date() <= 9 ? '0' + moment().date() : moment().date()
      let endDay      = this.year === moment().year() && this.month === (moment().month() + 1) ? atDay:  moment(momentValue, "YYYY/MM").daysInMonth()
      let startDate   = momentValue +  '/' +  '01';
      let endDate     = momentValue + '/' + endDay;
      return startDate + '-' + endDate;
    },
    // 日期筛选
    onSelectChange(e){
      if(e > 12){
        // 更新可选月份信息
        this.month_options = new Array(
            moment().year() === this.year ?
                moment().month() + 1: 12).fill({ value: '', label: '' }).map((val, index) => {
          index += 1;
          return{
            value: index,
            label: index + '月'
          }
        })
        // 检查列表可选月份是否包含如不包含默认成1月
        if(this.month_options.find(val => val.value === this.month) < 0) this.month = 1;
      }
      // 初始化一下分页信息
      this.pages.current = 1;
      this.getList()
    },
    // 分页
    pagesChange(e){
      if(e != this.pages.current){
        this.pages.current = e;
        this.getList()
      }
    },
    // 获取当前页数据
    getList(){
      returnedDetail({
        time    : this.year+ '-' + this.month,
        offset  : this.pages.current,
        size    : 10,
      }).then(res => {
        let { code, data, message } = res;
        if(code === 0){
          this.allCny = data.allCommission
          this.yt     = data.is_yt
          this.total  = data.rebate.total
          this.pages  = {
            current: data.rebate.current_page,
          }
          this.lists  = data.rebate.data
          return
        }
        this.$message.error(message)
      })
    }
  },
}
</script>